import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import FullscreenImage from "../components/FullscreenImage";
import { withTranslation } from 'react-i18next';
import Loader from "react-loader-spinner";

const styles = {
  root: {
    maxWidth: 400,
    flexGrow: 1,
    minHeight: 533,
    position: "relative",
    backgroundColor: "#060606"
  },
  img: {
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%"
  }
};

const PlaceListPhotoStepper = ({ openStatus, photoURLs, classes, t }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state to track loading status
  const [loadingError, setLoadingError] = useState(true);
  
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // New handlers for image loading
  const handleImageLoaded = () => {
    setLoadingError(false);
    setIsLoading(false);
  }
  const handleImageErrored = () => {
    setIsLoading(false);
    setLoadingError(true);
  };

  useEffect(() => {
    //setIsLoading(true);
    setLoadingError(false);
  }, [photoURLs]);

  const steps = photoURLs && photoURLs.length > 0 ? photoURLs.map((photoURL) => ({ imgPath: photoURL })) : [];
  const maxSteps = steps.length;
  const selectedImageURL = photoURLs && photoURLs.length > 0 ? steps[activeStep].imgPath : "";

  return (
    <div className={classes.root}>
      <FullscreenImage 
        open={open}
        close={handleClose}
        src={selectedImageURL}
      />
      {isLoading && (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#131313", height: "100%", width: "100%", position: 'absolute', zIndex: 2}}>
        <Loader
          type="Puff"
          color="#272727" 
          height={50} 
          width={50}
          timeout={3000} 
        />
        </div>
      )}
      {loadingError && (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center",backgroundColor: "#131313", height: "100%", width: "100%", position: 'absolute', zIndex: 2}}>
          <ErrorOutline style={{color:"#ddd"}} />
        </div>
      )}
      <img
        className={classes.img}
        id="current-place-image"
        src={selectedImageURL}
        alt="Hazard Place"
        style={{ display: isLoading ? 'none' : 'block' }} // Hide image while loading
        onLoad={handleImageLoaded}
        onError={handleImageErrored}
        onClick={handleOpen}
      />
      {maxSteps > 1 && (
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          className="slide-show-stepper-buttons"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              className={activeStep === maxSteps - 1 ? 'is-disabled-btn' : null}
              disabled={activeStep === maxSteps - 1}
            >
              {t("Next")}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              className={activeStep === 0 ? 'is-disabled-btn' : null}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              {t("Back")}
            </Button>
          }
        />
      )}
    </div>
  );
};

export default withStyles(styles)(withTranslation()(PlaceListPhotoStepper));
