import React, { Component } from 'react';
import axios from 'axios';
import { OpenVidu } from 'openvidu-browser';
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import RotateRightIcon from "@material-ui/icons/RotateRight";


import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import PersonIcon from "@material-ui/icons/Person";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RecordButton from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import MuteIcon from '@material-ui/icons/VolumeOff';
import UnMuteIcon from '@material-ui/icons/VolumeUp';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ChatIcon from '@material-ui/icons/Chat';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { withTranslation } from 'react-i18next';

import HazardLiveSnapshot from "./HazardLiveSnapshot";
import AlertSnackbar from "./AlertSnackbar";

import OpenViduVideoComponent from './OpenViduVideoComponent';
import LiveTrackingMap from "./maps/LiveTrackingMap";

import firebase from "firebase/app";
import 'firebase/database';
import ExportKmlButton from "./ExportKmlButton";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);


class LiveStreamSingle extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mySessionId: null,
        myUserName: null,
        userId: null,
        session: undefined,
        mainStreamManager: undefined,
        subscribers: [],
        rotateVideo: 0,
        joinedSession: false,
        speak: false,
        storageVideoURL: null,
        realtimeCoordinates: [],
        realtimePaths: [],
        visibleMap: false,
        fallbackLat: 35.558988,
        fallbackLng: 139.942829,
        timeOffsetSeconds: 8,
        map:null,
        marker: null,
    };

    this.mapRef = React.createRef();
    this.OV = React.createRef();

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);

  }

  handleWindowClose = () => {
    this.leaveSession();
  }

  setupSession() {
    this.setState(
      {mySessionId: this.props.place, myUserName: this.props.user.name, userId: this.props.user.id, parentGroupId: this.props.parentGroup.id},
      () => {
        this.joinSession();
      }
    );
  }

  setupStorageVideo() {
    this.setState(
      {storageVideoURL: this.props.place.video[0]},
      () => {
        console.log("Storage video");
      }
    );
  }

  setCurrentTime = currentTime => {
    // todo
    if (this.state.realtimeTracking && this.state.realtimePaths.length) {
      const currentPaths = this.state.realtimePaths.filter(path => {
  
        if (path.currentVideoTime <= currentTime) {
          path.setVisible(true);
          return true;
        } else {
          path.setVisible(false);
          return false
        }
  
      }).sort((pathA, pathB) => pathA.currentVideoTime > pathB.currentVideoTime? 1 : -1);
  
      const seekedPath = currentPaths[currentPaths.length - 1];

      if (seekedPath) {
        const seekedPathLat = seekedPath.getPath().getAt(1).toJSON().lat;
        const seekedPathLng = seekedPath.getPath().getAt(1).toJSON().lng;

        this.updateMarkerBounds(seekedPathLat,seekedPathLng);
      } else {
        const initialCoords = this.props.place && this.props.place.geo;

        const initialLat = initialCoords.latitude;
        const initialLng = initialCoords.longitude;

        this.updateMarkerBounds(initialLat, initialLng);
      }
    }
    
    if (this.player) {

      this.setState({ currentTime }, () => {
        this.player.currentTime = currentTime;
      });

    }
  };

  componentDidUpdate(prevProps, prevState) {
    if((!prevState.mySessionId && this.props.place) 
        && (typeof this.props.place === 'string' || this.props.place instanceof String)
        && (this.state.mySessionId === null)
        && (this.props.parentGroup.id)
        && this.props.open
        && this.props.user) {
      //is live stream - place is a string id
      this.setupSession();
      // first time opening the map while connected, get realtime data
     
    } else if((!prevState.mySessionId && this.props.place) && (this.props.place.video) && (!prevState.storageVideoURL && this.props.place.video[0]) && (this.state.mySessionId === null) && (this.props.parentGroup.id) && this.props.open) {
      //is storage - place is a object
      this.setupStorageVideo();
    }

    if (this.state.map && this.state.realtimeCoordinates.length === 0) {
      //get user id
      var a = this.props.place.split("PLA-TNHP-1-LIVE-")[1]
      var b = a.split("-");
      b.pop();
      var producerId = b.join('-');
      this.getFirebaseCoords(producerId,this.props.place);
    }
    /*
    if (!prevState.map && this.state.map && this.state.realtimeTracking) {
      window.google.maps.event.addListenerOnce(
        this.state.map,
        "tilesloaded",
        () => {
          if (!this.state.marker) {
            if (this.props.isWebRTC && this.props.roomInfo) {
              // update map for realtime tracking
            } else {
              //this.updateRealtimeMarker();
              //this.updateRealtimeMapStorage();
            }
          }
        }
      );
    }
    */

  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onbeforeunload);
    window.addEventListener("resize", this.setVideoContainerHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onbeforeunload);
    window.removeEventListener("resize", this.setVideoContainerHeight);
  }

  setVideoContainerHeight = () => {
    if (this.videoContainer) {
      var videoContainerHeight = this.videoContainer.offsetHeight;
      this.setState({
        videoContainerHeight: videoContainerHeight
      });
    }
  };

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
        mySessionId: e.target.value,
    });
  }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream
      });
    }
  }


  deleteSubscriber(streamManager) {
    let subList = this.state.subscribers;
    let index = subList.indexOf(streamManager, 0);
    if (index > -1) {
      subList.splice(index, 1);
      this.setState({
        subscribers: subList,
      });
    }
  }

  joinSession = async() => {
    if(this.state.mySessionId && this.state.myUserName && this.props.parentGroup.id && this.state.userId) {
      // --- 1) Get an OpenVidu object ---
      this.OV = new OpenVidu();
      // --- 2) Init a session ---

      this.setState(
          {
            session: this.OV.initSession(),
          },
          async() => {
            var mySession = this.state.session;

            // --- 3) Specify the actions when events take place in the session ---

            // On every new Stream received...
            mySession.on('streamCreated', (event) => {
              console.log("streamCreated mySession trigger")

              // Subscribe to the Stream to receive it. Second parameter is undefined
              // so OpenVidu doesn't create an HTML video by its own
              var subscriber = mySession.subscribe(event.stream, undefined);
              var subscribersUpdate = [];
              subscribersUpdate.push(subscriber);

              subscribersUpdate.forEach((s) => {console.log("dev_sub_info", s)});
              // Update the state with the new subscribers
              this.setState({
                subscribers: subscribersUpdate,
              });
            });
            // On every Stream destroyed...
            mySession.on('streamDestroyed', (event) => {
              console.log("streamDestroyed mySession trigger")
              console.log(event.stream);
              // Remove the stream from 'subscribers' array
              this.deleteSubscriber(event.stream.streamManager);
              //close the session
              this.handleWindowClose();
            });

            // On every asynchronous exception...
            mySession.on('exception', (exception) => {
              console.log("exception mySession trigger")
              console.warn(exception);
            });

            mySession.on('reconnecting', () => console.warn('Oops! Trying to reconnect to the session'));
            mySession.on('reconnected', () => console.log('Hurray! You successfully reconnected to the session'));

            mySession.on('sessionDisconnected', (event) => {
              console.log("sessionDisconnected event triggered")
              if (event.reason === 'networkDisconnect') {
                console.warn('Dang-it... You lost your connection to the session');
              } else {
                // Disconnected from the session for other reason than a network drop
                console.log(event);
              }
            });

            // --- 4) Connect to the session with a valid user token ---
            try {

              //console.log("attempting to create session");

              var data = JSON.stringify({sessionId: this.state.mySessionId, userCompanyGroup: this.props.parentGroup.id});
              console.log(`Connecting to session using this data: ${data}`)
              var resToken = await axios.post('https://livego-api.mmdev.jp/openvidu-view', data,{
              headers: {
                'Content-Type': 'application/json',
              },
              });

              if(resToken && resToken.data && resToken.data.token) {
                const token = resToken.data.token;
                
                //maybe we can fix this connection if we change the clientData? Doubt it but heres a random generator
                //let r = (Math.random() + 1).toString(36).substring(2);

                mySession.connect(token,{ clientData: this.state.userId }).then(async () => {
                  var devices = await this.OV.getDevices();
                  var videoDevices = devices.filter(device => device.kind === 'videoinput');
                  // --- 5) Get your own camera stream ---
                  // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                  // element: we will manage it on our own) and with the desired properties

                  let pub = await this.OV.initPublisherAsync(undefined, {
                      audioSource: undefined, // The source of audio. If undefined default microphone
                      videoSource: false, // The source of video. If undefined default webcam
                      publishAudio: this.state.speak, // Whether you want to start publishing with your audio unmuted or not
                      publishVideo: false, // Whether you want to start publishing with your video enabled or not
                      resolution: '640x480', // The resolution of your video
                      frameRate: 30, // The frame rate of your video
                      insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                      mirror: false, // Whether to mirror your local video or not
                  });

                  //
                  mySession.publish(pub);
                  // Set the main video in the page to display our webcam and store our Publisher
                  this.setState({
                    mainStreamManager: pub,
                  });

                }).catch((error) => {
                  console.log("connect error via mySession = error below")
                  console.log(error)
                  console.log('There was an error connecting to the session:', error.code, error.message);
                });


              } else {
                console.log("Error ResToken");
              }
            } catch (e) {
              console.log("app side")
              console.log(e);
            }              
          }
        );
      }
    }

    hasLevelIntString = (levelInt) => {
      const {t} = this.props;
      switch (levelInt) {
        case "1":
          return t("Alert 4");
        case "2":
          return t("Alert 3");
        case "3":
          return t("Alert 2");
        case "4":
          return t("Alert 1");
        default:
          break;
      }
    };

    leaveSession = async() => {

        // --- 7) Leave the session by calling 'disconnect' method over the Session object ---

        const mySession = this.state.session;

        if (mySession) {
          console.log("DISCCONECTING SESSION SHOULD CLOSE OUT MEDIA")
          mySession.disconnect();
        }


        // Empty all properties...
        this.OV = null;

        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: null,
            myUserName: null,
            mainStreamManager: undefined,
            rotateVideo: 0,
            joinedSession: false,
            storageVideoURL: null,
            speak: false,
            currentVideoDevice: null,
            realtimeCoordinates: [],
            realtimePaths: [],
            visibleMap: false,
            fallbackLat: 35.558988,
            fallbackLng: 139.942829,
            timeOffsetSeconds: 8,
            map:null,
            marker: null,
        });
        console.log("left the session");
        this.props.handleClose();
    }

    forceRemoteCloseSession = async() => {
      try {
        var data = JSON.stringify({sessionId: this.state.mySessionId});
        var resToken = await axios.post('https://livego-api.mmdev.jp/close-session', data,{
        headers: {
          'Content-Type': 'application/json',
        },
        });
        this.leaveSession();
      } catch(e) {
        console.log(e);
      }
    }

    getFirebaseCoords = (producerId, placeId) => {

    /* 
      Room Info [
        {
          "roomId":"PLA-TNHP-1-LIVE-USE-TNHP-1-T-XXXXX-1234566778",
          "namespace":"Telenet",
          "producer":"USE-TNHP-1-T-gdfsgdf4242",
          "groupName":"MapMotion",
          "username":"DeveloperUserMM",
          "title":null,
          "hazardLevel":"大至急！",
          "privateRoom":true
        }
      ]
    */

    const selectedRoomInfo = producerId && placeId;

    if (selectedRoomInfo) {
      this.realtimeRef = firebase.database().ref(`realtime/${producerId}/${placeId}`);
      //realtime/USE-TNHP-1-T-gdfsgdf4242/PLA-TNHP-1-LIVE-USE-TNHP-1-T-XXXXX-1234566778

      this.realtimeRef.on("value", (snapshot) => {
        
        if (snapshot.val()) {
          // Stream data available
          this.updateRealtimeMapLive(snapshot.val());
        }
        
      }, (errorObject) => {
        console.log("The read failed: " + errorObject.code);
      });
    }
  };

  updateRealtimeMarker = () => {
    const map = this.state.map;
    const marker = this.state.marker;

    if (marker) {
      marker.setVisible(false);
      window.mc.removeMarker(marker);
      marker.setMap(null);
    }

    if (map) {
      const customData = this.props.user && this.props.user.customData;
      const initialCoords = this.props.place && this.props.place.geo;
      const RealtimeIcon = {
        anchor: new window.google.maps.Point(16, 24),
        url: "https://geocore-static.s3.ap-northeast-1.amazonaws.com/realtime-location-icon.svg",
        scaledSize: new window.google.maps.Size(32, 32)
      };

      const lat = initialCoords? initialCoords.latitude: 
                  customData && customData.initialLat? parseFloat(customData.initialLat): 
                  this.state.fallbackLat;
      const lng = initialCoords? initialCoords.longitude: 
                  customData && customData.initialLng? parseFloat(customData.initialLng): 
                  this.state.fallbackLng;

      const updatedMarker = new window.google.maps.Marker({
        position: {
          lat,
          lng 
        },
        icon: RealtimeIcon,
        map
      });

      this.setState({ marker: updatedMarker });
      map.setCenter({ lat, lng });
    }
  };

  updateRealtimeMapLive = realtimeData => {
    const updatedCoords = Object.values(realtimeData).sort((coordA, coordB) => coordA.duration - coordB.duration);
    
    const { realtimeCoordinates, map } = this.state;
    const updatedPaths = [];
    
    updatedCoords.forEach((updatedCoord, coordIndex) => {
      const currentCoord = updatedCoords[coordIndex];
      const prevCoord = updatedCoords[coordIndex - 1];
      const secondsLimit = 120;

      if (realtimeCoordinates.some(realtimeCoord => realtimeCoord.id === updatedCoord.id)) {
        // already added
      } else {
        // needs added
        if (prevCoord) {
          const secondsDiff = currentCoord.duration - prevCoord.duration;
    
          if (secondsDiff > secondsLimit) {
            const dottedLineSymbol = {
              path: "M 0,1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
    
            const dottedPath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              strokeOpacity: 0,
              strokeColor: "#43dcd7",
              icons: [
                {
                  icon: dottedLineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
              map
            });
    
            updatedPaths.push(dottedPath);
          } else {
            // Last coord in test data
            const realtimePath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              geodesic: true,
              strokeColor: "#43dcd7",
              strokeOpacity: 1.0,
              strokeWeight: 5,
              map
            });
    
            updatedPaths.push(realtimePath);
          }
        }
      }

      if (coordIndex === updatedCoords.length - 1) {
        
        if (this.state.marker) {
          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ]
          }));

          this.updateMarkerBounds(currentCoord.lat, currentCoord.lng);
        } else {
          // Initial marker and map position has not been set yet
          console.log("Initial marker and map position has not been set yet");
          const RealtimeIcon = {
            anchor: new window.google.maps.Point(16, 24),
            url: "https://geocore-static.s3.ap-northeast-1.amazonaws.com/realtime-location-icon.svg",
            scaledSize: new window.google.maps.Size(32, 32)
          };
    
          // most recent from firebase will be the first coord in the array
          const lat = currentCoord.lat;
          const lng = currentCoord.lng;
    
          const updatedMarker = new window.google.maps.Marker({
            position: {
              lat,
              lng 
            },
            icon: RealtimeIcon,
            map
          });

          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ],
            marker: updatedMarker
          }), () => {
            this.updateMarkerBounds(lat, lng);
          });
        }
      }
    }); 
  };

  toggleComment = () => {
    this.setState(prevState => ({
      viewComment: !prevState.viewComment
    }))
  }

  updateMarkerBounds = (lat, lng) => {
    const { map, marker, fallbackLat, fallbackLng } = this.state;
    
    if (map) {
      if (map.getBounds() && !map.getBounds().contains({ lat, lng })) {
        map.panTo({ lat, lng });
      }

      if (lat === fallbackLat && lng === fallbackLng) {
        // User has denied location tracking
        marker.setMap(null);

        const customData = this.props.user && this.props.user.customData;
        const initialCoords = this.props.place && this.props.place.geo;

        const lat = initialCoords? initialCoords.latitude: 
          customData && customData.initialLat? parseFloat(customData.initialLat): 
          fallbackLat;
        const lng = initialCoords? initialCoords.longitude: 
          customData && customData.initialLng? parseFloat(customData.initialLng): 
          fallbackLng;

        map.setCenter({ lat, lng });
      } else {
        marker.setPosition({ lat, lng });
      }
    }
  };

  updateCurrentTimeStorage = () => {

    //todo
    this.player.ontimeupdate = () => {
      // fix for when a player might be playing while the user closes the model
      const videoDuration = this.player && this.player.duration === Infinity? null : this.player && this.player.duration? this.player.duration: null;
      
      if(this.player && this.player.currentTime) {
        this.setState({ 
          currentTime: this.player.currentTime, 
          videoDuration 
        });
        
        if (this.state.realtimeTracking) {
          const roundedCurrentTime = Math.floor(this.player.currentTime)
  
          if (this.state.realtimeCoordinates.length > 0) {
            const currentPath = this.state.realtimePaths.find(testPath => testPath.currentVideoTime === roundedCurrentTime);
            const currentCoord = this.state.realtimeCoordinates.find(testCoord => testCoord.duration === roundedCurrentTime);
            const alreadyAdded = currentPath && currentPath.visible;
  
            if (currentCoord && currentPath && !alreadyAdded) {
              this.updateMarkerBounds(currentCoord.lat, currentCoord.lng);
              currentPath.setVisible(true);
            }
          }
        }
      }

    };
  };


  updateRealtimeMapStorage = () => {
    const { realtimeData } = this.props.place;
    const realtimeStorageData = JSON.parse(realtimeData);

    const realtimePaths = [];
    const secondsLimit = 120;
    
    realtimeStorageData.forEach((coord, coordIndex) => {
      const currentCoord = realtimeStorageData[coordIndex];
      const prevCoord = realtimeStorageData[coordIndex - 1];

      coord.duration -= this.state.timeOffsetSeconds;

      if (prevCoord) {
        const secondsDiff = currentCoord.duration - prevCoord.duration;
  
        if (secondsDiff > secondsLimit) {
          const dottedLineSymbol = {
            path: "M 0,1 0,1",
            strokeOpacity: 1,
            scale: 4,
          };
  
          const dottedPath = new window.google.maps.Polyline({
            path: [
              {
                lat: prevCoord.lat,
                lng: prevCoord.lng
              },
              { 
                lat: currentCoord.lat,
                lng: currentCoord.lng
              },
            ],
            strokeOpacity: 0,
            strokeColor: "#43dcd7",
            icons: [
              {
                icon: dottedLineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ],
            currentVideoTime: coord.duration,
            map: this.state.map,
            visible: false,
          });
  
          realtimePaths.push(dottedPath);
        } else {
          // Last coord in test data
          const realtimePath = new window.google.maps.Polyline({
            path: [
              {
                lat: prevCoord.lat,
                lng: prevCoord.lng
              },
              { 
                lat: currentCoord.lat,
                lng: currentCoord.lng
              },
            ],
            geodesic: true,
            strokeColor: "#43dcd7",
            strokeOpacity: 1.0,
            strokeWeight: 5,
            currentVideoTime: coord.duration,
            map: this.state.map,
            visible: false,
          });
  
          realtimePaths.push(realtimePath);
        }
      }

      if (coordIndex === realtimeStorageData.length - 1) {
        this.setState({
          realtimeCoordinates: realtimeStorageData,
          realtimePaths,
        }, () => {
          this.setCurrentTime(this.state.currentTime);
        });
      }
    });
  };

  toggleSpeak = () => {
    if(this.state.mainStreamManager) {
      this.state.mainStreamManager.publishAudio(!this.state.speak);
    }
    this.setState({ speak: !this.state.speak });
  }

    hasLevelName = (levelName, customName) => {
    let hazardName;
    const {t} = this.props;

    switch (levelName) {
      case "緊急度1":
        hazardName = customName ? customName : t("Alert 4");
        return hazardName;
      case "緊急度2":
        hazardName = customName ? customName : t("Alert 3");
        return hazardName;
      case "緊急度3":
        hazardName = customName ? customName : t("Alert 2");
        return hazardName;
      case "緊急度4":
        hazardName = customName ? customName : t("Alert 1");
        return hazardName;
      default:
        break;
    }
  };

  handleRotate = (direction, currentRotate) => {
    var newRotate = null;

    if (direction === "left") {
      newRotate = currentRotate - 90;
    } else {
      //it's right
      newRotate = currentRotate + 90;
    }

    if (newRotate > 360) {
      newRotate = -90;
    } else if (newRotate < -360) {
      newRotate = 90;
    }

    var videoContainerHeight = this.videoContainer.offsetHeight;
    this.setState({
      rotateVideo: newRotate,
      videoContainerHeight: videoContainerHeight
    });
  };

  toggleRealtimeTracking = () => {
    // Only set up map once, if user toggles on and off, toggle visibility only
    if (this.state.realtimeTracking) {
      this.setState(prevState => ({
        visibleMap: !prevState.visibleMap,
      }));
    } else {
      this.setState({
        realtimeTracking: true,
        visibleMap: true
      });
    }
  };

  deletePlace = () => {
    const {t} = this.props;

    let confirmAction = window.confirm(t("Are you sure you want to delete this recording?"));
    if (confirmAction) {
      this.props.handleMarkAsDeleted(this.props.place);
      this.handleWindowClose();
    }
  }

  render() {
    const rotateVideoTag =
      this.state.rotateVideo === 0
        ? ""
        : this.state.rotateVideo === 90
        ? "rotate-90"
        : this.state.rotateVideo === 180
        ? "rotate-180"
        : this.state.rotateVideo === 270
        ? "rotate-270"
        : this.state.rotateVideo === 360
        ? "rotate-360"
        : this.state.rotateVideo === -90
        ? "rotate-90-neg"
        : this.state.rotateVideo === -180
        ? "rotate-180-neg"
        : this.state.rotateVideo === -270
        ? "rotate-270-neg"
        : this.state.rotateVideo === -360
        ? "rotate-360-neg"
        : "";
    const customData = this.props.user && this.props.user.customData;
    const realtimeStorageData = this.props.open && this.props.place && this.props.realtimeData;
    const {t} = this.props;

    return (
      <Dialog
        fullScreen
        open={this.props.open? this.props.open:false}
        className="place-modal live-streaming-modal"
      >
          {this.state.creatingClip?
          <div className="creating-clip-loader">
            <div className="loader-holder">
              <span>Generating Clip</span>
              <CircularProgress />
            </div>
          </div>:null
        }
        <HazardLiveSnapshot
          open={this.props.snapshotOpen}
          close={this.props.handleSnapshotClose}
          download={() => this.props.handleVideoSnapshot(true)}
          createPlace={this.props.createPlace}
          createPlaceLoading={this.props.createPlaceLoading}
        />
        <AlertSnackbar
          isType="error"
          isOpen={this.state.hasError}
          handleClick={this.handleErrorAlertClick}
          message={this.state.errorMsg}
        />
        <div className="place-list-buttons live-stream-single-buttons">

          {this.props.place?
            <LightTooltip 
              title="No realtime data"
              disableFocusListener
              disableTouchListener
              disableHoverListener={this.props.openViduStreamPlaces && this.props.place || realtimeStorageData} 
              arrow
            >
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`tracking-btn ${this.state.visibleMap? 'on': 'off'} ${this.props.openViduStreamPlaces && this.props.place? '': realtimeStorageData? '' : 'disabled'}`}
                  onClick={this.toggleRealtimeTracking}
                  disabled={this.props.openViduStreamPlaces && this.props.place? false: !realtimeStorageData}
                >
                  <div className={`tracking-status-container ${this.state.visibleMap? 'on': 'off'} ${this.props.openViduStreamPlaces && this.props.place? '': realtimeStorageData? '' : 'disabled'}`}>
                    <StatusIcon className={`status-icon ${this.props.openViduStreamPlaces && this.props.place? '': realtimeStorageData? '' : 'disabled'}`} />
                    <Typography className={`tracking-status ${this.state.visibleMap? 'on': 'off'} ${this.props.openViduStreamPlaces && this.props.place? '': realtimeStorageData? '' : 'disabled'}`}>
                      {this.state.visibleMap? "on": "off"}
                    </Typography>
                  </div>
                  {t("Real-time Tracking")}
                </Button>
              </span>
            </LightTooltip>
          : null}
          <Button onClick={this.handleWindowClose} className="close-button live" id="livestream_detail_window">
            <CloseIcon />
          </Button>
        </div>


        <div className={`live-stream-box ${rotateVideoTag}`}>

          {this.props.openViduStreamPlaces && this.props.place && this.props.openViduStreamPlaces[this.props.place]? (
            <>
            <div className="is-live-tag fw-stream">LIVE</div>
            <div className="hazard-level-label">
              <span className={`hazard-level-${this.props.openViduStreamPlaces[this.props.place].tags[0].id.split("-")[this.props.openViduStreamPlaces[this.props.place].tags[0].id.split("-").length - 1]}`}>
                {this.hasLevelIntString(this.props.openViduStreamPlaces[this.props.place].tags[0].id.split("-")[this.props.openViduStreamPlaces[this.props.place].tags[0].id.split("-").length - 1])}
              </span>
            </div>
            <Typography
              variant="h4"
              className="place-list-item-group-name"
            >
              {this.props.openViduStreamPlaces[this.props.place].customData["group.name"]}
            </Typography>
            <div className="place-list-item-username-container">
              <PersonIcon />
              <Typography variant="h5" className="username">
                {this.props.openViduStreamPlaces[this.props.place].customData["user.name"]}
              </Typography>
            </div>
            <div>
              {this.props.openViduStreamPlaces[this.props.place].shortDescription?
                <Typography
                  variant="h2"
                  className="place-list-item-title"
                >{this.props.openViduStreamPlaces[this.props.place].shortDescription}</Typography>:null
              }
            </div>
            </>
          ):(
          <>
          {this.props.place && this.props.place.video ?<>
            <div className="is-storage-tag fw-stream">{t("Storage")}</div>

            <div className="hazard-level-label">
              <span className={`hazard-level-${this.props.place.hazardLevel.name.split("")[this.props.place.hazardLevel.name.length - 1]}`}>
                {this.hasLevelName(this.props.place.hazardLevel.name,this.props.place.hazardLevel.customName)}
              </span>
            </div>

            <Typography variant="h4" className="place-list-item-group-name">
              {this.props.place.groupName}
            </Typography>

            <div className="place-list-item-username-container">
              <PersonIcon />
              <Typography variant="h5" className="username">
                {this.props.place.user}
              </Typography>
            </div>

            <div>
              {this.props.place.title?
                <Typography
                  variant="h2"
                  className="place-list-item-title"
                >{this.props.place.title}</Typography>:null
              }
            </div>

          </>:null}
          </>)
        }


            {this.state.session === undefined && this.state.storageVideoURL === null ? (
              <div className="video-loading">
                <div className="loader-holder">
                  <CircularProgress />
                </div>
              </div>
            ) : null}

            {this.state.session !== undefined || this.state.storageVideoURL !== null? (
            <div id="session">
              <div id="video-container"
                className={`video-display-block ${this.state.visibleMap? 'realtime-tracking': ''}`}
                ref={streambox => (this.videoContainer = streambox)}
              >

                {this.state.subscribers && this.state.subscribers.length > 0?
                  <div className="stream-container"
                    style={
                        this.state.visibleMap &&                                 
                        (this.state.rotateVideo === 90 ||
                        this.state.rotateVideo === -90 ||
                        this.state.rotateVideo === 270 ||
                        this.state.rotateVideo === -270)? 
                      {
                        objectFit: "none"
                      } 
                        :
                      {
                        width:
                          this.state.rotateVideo === 90 ||
                          this.state.rotateVideo === -90 ||
                          this.state.rotateVideo === 270 ||
                          this.state.rotateVideo === -270
                            ? this.state.videoContainerHeight
                            : "100%"
                      }
                    }
                  >
                    <OpenViduVideoComponent key={this.state.subscribers[0].id} subscriber={this.state.subscribers[0]} />
                  </div>
                :null}

                {this.state.storageVideoURL?
                  <>
                  <video className="storage-video-elm" controls autoPlay>
                    <source Src={this.state.storageVideoURL} Type={"video/mp4"} />
                  </video>
                  </>
                :null}

              </div>

              {this.props.place.comment && this.state.canViewComment?
                <div className="comment-live-stream-button">
                {this.state.viewComment?
                  <div className="comment-text-window">
                    <p>{this.props.place.comment}</p>
                  </div>:null
                }
                <Fab color="primary" onClick={this.toggleComment}>
                  {this.state.viewComment?
                    <HighlightOffIcon />:
                    <ChatIcon />}
                </Fab>                            
                </div>:null
              }

            </div>     
        ) : null
        }
        </div> 


         <div className="stream-control-panel">
            <div className="top-layer-controls">
              <div className="rotate-and-full-controls livestream-single-controls">


                {this.props.place && this.props.place.video? <></>:

                <>

                {this.props.place && !this.state.session && !this.props.place.video?
                <>
                <h1 class="loading-place-id"
                >{t("Loading...")} {this.props.place? this.props.place.placeId: ""}
                <span class="delete" onClick={this.deletePlace}>{t("Delete")}</span>
                </h1>
                </>:null}

                {this.state.session !== undefined ? 
                <>


                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.handleRotate("left", this.state.rotateVideo)
                  }
                  className="edit-button rotate rotate-left"
                  style={{marginRight: 10}}
                >
                  <RotateLeftIcon />
                </Button>


                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.toggleSpeak()
                  }
                  className={`edit-button rotate rotate-right ${this.state.speak? 'mic-on': 'mic-off'}`}
                >
                {this.state.speak?
                  <MicIcon />:
                  <MicOffIcon />}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.handleRotate("right", this.state.rotateVideo)
                  }
                  style={{marginLeft: 10}}
                  className="edit-button rotate rotate-right"
                >
                  <RotateRightIcon />
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.forceRemoteCloseSession()
                  }
                  className={`force-close-session-button`}
                >
                <CloseIcon/>
                <p>{t("Remote Termination")}</p>
                </Button>
                </>
                :null}


                </>

              }
              {realtimeStorageData? 
                <ExportKmlButton realtimeStorageData={realtimeStorageData} />                            
              :null}

              </div>
            </div>
        </div>

        {this.state.realtimeTracking?
        <div className={`tracking-map-container ${this.state.visibleMap? "visible-tracking-map": "hide-tracking-map"}`} ref={this.mapRef}>
          {/* <div className="tracking-map-resize" onMouseDown={this.handleResizeMouseDown}></div> */}
          <LiveTrackingMap
            id="tracking-map"
            options={{
              center: {
                lat: this.state.realtimeCoordinates.length > 0? 
                      this.state.realtimeCoordinates[this.state.realtimeCoordinates.length - 1].lat: 
                      customData && customData.initialLat? parseFloat(customData.initialLat): 
                      this.state.fallbackLat,
                lng: this.state.realtimeCoordinates.length > 0 ? 
                      this.state.realtimeCoordinates[this.state.realtimeCoordinates.length - 1].lng: 
                      customData && customData.initialLng? parseFloat(customData.initialLng): 
                      this.state.fallbackLng
              },
              zoom: customData && customData.initialZoom? parseInt(customData.initialZoom): 6,
              maxZoom: 30,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
            onMapLoad={map => {
              this.setState({ map });
            }}
            
          />
        </div>
      : null}


      </Dialog>
    );
  }

}

export default withTranslation()(LiveStreamSingle);
