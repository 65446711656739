import React, {useEffect, useState} from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from '@material-ui/icons/SaveAlt';
import { ReactComponent as PhotoCamera } from "../images/camera-icon.svg";
import { ReactComponent as VideoCamera } from "../images/video-camera.svg";
import PersonIcon from "@material-ui/icons/Person";
import PlaceListPhotoStepper from "./PlaceListPhotoStepper";
import { saveAs } from 'file-saver';
import Loader from "react-loader-spinner";

import LiveStreamVideoPlayer from './LiveStreamVideoPlayer';
import LiveStreamStoragePlayer from './LiveStreamStoragePlayer';
import { useTranslation } from 'react-i18next';


const styles = {
  taisakuTag: {
    backgroundColor: "#0D6AAD",
    color: "white",
    marginTop: "40px",
    borderRadius: "5px",
    padding: "4px",
    position: "absolute"
  }
};

const hasLevelName = (levelName, customName, t) => {
  let hazardName;
  switch (levelName) {
    case "緊急度1":
      hazardName = customName? customName : t("Alert 4");
      return hazardName;
    case "緊急度2":
      hazardName = customName? customName :  t("Alert 3");
      return hazardName;
    case "緊急度3":
      hazardName = customName? customName :  t("Alert 2");
      return hazardName;
    case "緊急度4":
      hazardName = customName? customName : t("Alert 1");
      return hazardName;
    default:
      break;
  }
};


const getIncidentTagNames = incidentTags => {   
  return incidentTags.map(incidentTag => (
    <div className="place-list-incident-tag">
      <div className={`place-list-incident-tag-color ${incidentTag.customData && incidentTag.customData.color? incidentTag.customData.color : ""}`}></div>
      <Typography variant="h5" className="place-list-incident-tag-name">
        {incidentTag.name}
      </Typography>
    </div>
  ));
};


const ModalPlaceDetails = props => {
  const [loading, setLoading] = useState(false);
  const { place, open, handleEditPlaceOpen, handleDownloadPlace, filterByTaisaku, groups } = props;
  const tagId = open ? place.hazardLevel.id.split("-") : null;
  const hazardLevel = tagId ? tagId[tagId.length - 1] : null;
  const levelName = open? place.hazardLevel.name : null;
  const customName = open? place.hazardLevel.customName : null;
  const hasVideoURL = open? place.video : null;
  const isLiveStream = open? place.isLiveStream: null;

  const isStreaming = open? place.streaming: null;
  const hasStreamStorage = open? place.streamHasStorage: null;
  const { t } = useTranslation();

  const loadingPDF = (e, place) => {
    e.preventDefault();
    setLoading(true);
    handleDownloadPlace(place);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      className="place-modal"
    >
      {hasVideoURL && !isStreaming? 
        <div className="place-modal-left-col">
          <div className="video-column-wrapper">
            <video style={{ width: "100%", height: "100%" }} controls>
              <source src={place.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        :     
        <div className="place-modal-left-col">
          {isLiveStream?
            <React.Fragment>
              {isStreaming?
                <LiveStreamVideoPlayer videoURL={`https://hazardlive.geocore.jp/hls/${place.placeId}.m3u8`} />:
                <React.Fragment>
                  {hasStreamStorage?
                    <LiveStreamStoragePlayer placeId={place.placeId} />:
                    <div className="pending-stream-storage"></div>
                  }
                </React.Fragment>
              }
            </React.Fragment>:
            <PlaceListPhotoStepper openStatus={open} photoURLs={open? place.images.filter((im) => !im.includes("low")) : null} />
          }
        </div>
      }
      <div className="place-modal-right-col">
        <div className="place-modal-info">
          <div className="hazard-level-label">
            <span className={`hazard-level-${hazardLevel}`}>
              {hasLevelName(levelName, customName, t)}
            </span>
          </div>
          {place && place.isPublic?
            <div className="public-place-label-modal">
              {t("Public")}
            </div>:null
          }
          {open && filterByTaisaku && place.taisaku === "true" ? 
            <div className="taisaku-place-label-modal" variant="h6">{t("Resolve")}</div> 
          : null}
          <div className="place-list-item-date">
            <Typography variant="h3">{open ? place.createTime : ""}</Typography>
          </div>
          <Typography variant="h4" className="place-list-item-group-name">
            {open ? place.groupName : ""}
          </Typography>
          <div className="place-list-item-username-container">
            <PersonIcon />
            <Typography variant="h5" className="username">
              {open ? place.user : ""}
            </Typography>
          </div>
          <Typography variant="h5" className="place-list-item-notes large-notes">
            {open ? place.comment : ""}
          </Typography>
          {open && place.incidentTags.length > 0? 
            <div className="place-list-incident-tags-container">
              {getIncidentTagNames(place.incidentTags)}
            </div>:
            <div></div>
          }

          {!isLiveStream && open? 
            <div className="place-list-media-icon">
            {place.video?                       
              <VideoCamera />: 
              <PhotoCamera />}
            <div className="circle-counter-places">
              {place.video? <Typography>1</Typography>:
              <Typography>{
                place.images &&
                place.images[0] && 
                place.images.filter((im) => !im.includes("low")).length
              }</Typography>}
            </div>
          </div>
            :<div></div>
          }
          {open && place.hasRead?
            <div className="has-read-label">
              <Typography className="modal-read-label">
                {t("Viewed")}
              </Typography>
            </div>: <div></div>
          }

      </div>

      <div className="place-service-buttons">
      {place && place.images ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => 
              place.images
                .filter((img) => !img.includes("/low"))
                .forEach((img) => window.open(img, '_blank'))
            }
            className="edit-button download-no-text"
          >
            <DownloadIcon />
          </Button>
        ) : null}
        {place && place.images ? (
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={loading? null: (e) => loadingPDF(e, place)}
          className={loading? "edit-button loading-button" : "edit-button"}
        >
          {loading?
          <div className="loader-button-container">
            <Loader
            type="ThreeDots"
            color="#fff" 
            height={17} 
            width={30}
            timeout={3000} 
          />
          </div>
          :`${t("Print")} / PDF`}
        </Button>):null}
      </div>

      </div>
      <div className="place-list-buttons">
        {open && place && groups && groups.includes(place.groupId)?
          <Button     
            variant="contained"
            color="secondary"
            onClick={handleEditPlaceOpen}
            className="edit-button"
          >
            {t("Edit")}
          </Button>:null
        }
        <Button 
          onClick={props.handleClose} 
          className="close-button">
          <CloseIcon />
        </Button>
      </div>
      <Divider />
    </Dialog>
  );
};

export default withStyles(styles)(ModalPlaceDetails);
