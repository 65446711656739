import React from "react";
import FilterListPhotos from "../../components/FilterListPhotos";
import FilterListLive from "../../components/FilterListLive";

const Filters = ({
  isLiveApp,
  user,
  tags,
  incidentTags,
  groups,
  comments,
  parentGroup,
  levels,
  registerFilterItems,
  setDates,
  filterByTaisaku,
  toggleTaisakuFilters,
  filterRange,
  filterByLists,
  loadingPlaces,
  activeButton,
  removePlaceIncident,
  removeIncidentTag,
  updateIncidentPlaces,
  geocoreAddIncidentTag,
  storageLimit,
  hideAllPlaces
}) => {
  const isPhotosFilters = activeButton === 1;
  const hasCategories = tags ? tags : false;
  const hasIncidents = incidentTags ? incidentTags : false;
  const hasGroups = groups ? groups : false;
  const hasLevels = levels ? levels : false;
  return (
    <div>
      {hasCategories && hasGroups && hasLevels && hasIncidents && !loadingPlaces ? (
        <div>
          {isPhotosFilters?
          <div className={isPhotosFilters ? "show-photo-list" : "hide-photo-list"}>
            <FilterListPhotos
              isLiveApp={isLiveApp}
              user={user}
              tags={tags}
              incidentTags={incidentTags}
              groups={groups}
              comments={comments}
              parentGroup={parentGroup}
              hideAllPlaces={hideAllPlaces}
              levels={levels}
              registerFilterItems={registerFilterItems}
              setDates={setDates}
              filterByTaisaku={filterByTaisaku}
              toggleTaisakuFilters={toggleTaisakuFilters}
              filterRange={filterRange}
              filterByLists={filterByLists}
            />
          </div>
          :
          <div className={isPhotosFilters ? "hide-video-list" : "show-video-list"}>
            <FilterListLive
              isLiveApp={isLiveApp}
              groups={groups}
              registerFilterItems={registerFilterItems}
              filterByTaisaku={filterByTaisaku}
              filterByLists={filterByLists}
              filterRange={filterRange}
              setDates={setDates}
              hideAllPlaces={hideAllPlaces}
              incidentTags={incidentTags}
              parentGroup={parentGroup}
              removePlaceIncident={removePlaceIncident}
              removeIncidentTag={removeIncidentTag}
              updateIncidentPlaces={updateIncidentPlaces}
              geocoreAddIncidentTag={geocoreAddIncidentTag}
              tags={tags}
              levels={levels}
              storageLimit={storageLimit}
            />
          </div>}

        </div>
      ) : (
        null
      )}
    </div>
  );
};

export default Filters;