import React, { Component } from 'react';

export default class OpenViduVideoComponent extends Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate(props) {
        if (props && !!this.videoRef) {
            console.log("componentDidUpdate openvidu video element")
            var r = this.props.subscriber.addVideoElement(this.videoRef.current);
            console.log(r);
        }
    }

    componentDidMount() {
        if (this.props && !!this.videoRef) {
            console.log("componentDidMount openvidu video element")
            var r = this.props.subscriber.addVideoElement(this.videoRef.current);
            console.log(r);
        }
    }

    render() {
        return (
          <>
          {this.props.subscriber !== undefined ? (
            <>
                {this.props && this.props.muted?
                <video autoPlay={true} controls muted ref={this.videoRef} />:
                <video autoPlay={true} controls  ref={this.videoRef} />
                }

            </>
          ):<h3 style={{color: "#ffff"}}>Stream manager not found</h3>}
          </>
        )
    }

}
